/* You can add global styles to this file, and also import other style files */
@import "~ng-zorro-antd/ng-zorro-antd.min.css";

body {
  min-height: 100vh;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  background-color: #f6f8fa;
}

:root {
  --iris-text-color-primary: rgba(0, 0, 0, 0.85);
  --iris-text-color-secondary: rgba(0, 0, 0, 0.45);
  --iris-font-weight: 400;

  /*
    Font scale
    Src - https://type-scale.com/ 1.250 - Major Third
  */
  --iris-font-size-xs: 0.64rem;
  --iris-font-size-s: 0.8rem;
  --iris-font-size: 1rem;
  --iris-font-size-l: 1.25rem;
  --iris-font-size-xl: 1.563rem;
  --iris-font-size-xxl: 1.953rem;
  --iris-font-size-xxxl: 2.441rem;
  --iris-font-size-xxxxl: 3.052rem;

  --iris-divider-color: rgba(0, 0, 0, 0.06);
  --iris-border-color: rgba(0, 0, 0, 0.15);
  --iris-background-color-grey: rgba(0, 0, 0, 0.04);

  --header-height: 56px;
  --layout-padding: 1rem;
  --site-width: 1300px;
  --footer-height: 40px;
}

.client-sub-page-header {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 1.5rem;
}
